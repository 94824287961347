import { graphql } from 'gatsby';
// import { useForm } from 'react-hook-form';

import HelpRequest from 'components/HelpRequest';
import { PageWrapper } from 'components/Layout/PageWrapper';
import { HorizontalContainer } from 'components/UI/Containers';
import { HeadingBig, HeadingSmall } from 'components/UI/Headings';
// import { HeadingSmall } from 'components/UI/Headings';

// import { Form, InputFields, SubmitButtons } from 'components/UI/Form';
// import { PrimaryButton } from 'components/UI/Buttons';
// import { InputField } from 'components/UI/Input';

import Content from './Content';

const ContactsPageTemplate = ({ data, pageContext }) => {
  const {
    datoCmsContactsPage: pageData,
    datoCmsHowToRequest: requestData,
    datoCmsMiscTextString: translations,
    datoCmsFooter: { contacts: networks },
  } = data;
  const {
    seo,
    title,
    contactTitle,
    // contactFormTitle,
    // contactFormDescription,
    // contactFormInputPlaceholder,
    // contactFormInputLabel,
    // contactFormTextareaPlaceholder,
    // contactFormTextareaLabel,
    // contactFormButtonText,
    pageInfo,
    messengersList: messengers,
  } = pageData;

  // const {
  //   register,
  //   watch,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();
  // const onSubmit = (value) => console.log(value);

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.seoImage?.seoImageUrl}
    >
      <HorizontalContainer>
        <HeadingBig>{title}</HeadingBig>
        <section>
          <div>
            {/* <Form onSubmit={handleSubmit(onSubmit)}>
              <HeadingSmall as="h2">{contactFormTitle}</HeadingSmall>
              <p>{contactFormDescription}</p>
              <InputFields>
                <InputField
                  type="email"
                  name="emailField"
                  label={contactFormInputLabel}
                  placeholder={contactFormInputPlaceholder}
                  inputOptions={{ register, watch, errors, required: true }}
                />
                <InputField
                  type="textarea"
                  name="textareaField"
                  label={contactFormTextareaLabel}
                  placeholder={contactFormTextareaPlaceholder}
                  inputOptions={{ register, watch, errors, required: true }}
                />
              </InputFields>
              <SubmitButtons>
                <PrimaryButton
                  as="input"
                  type="submit"
                  value={contactFormButtonText}
                />
              </SubmitButtons>
            </Form> */}

            <HelpRequest content={requestData} />
            <div>
              <HeadingSmall as="h2">{contactTitle}</HeadingSmall>
              <Content {...{ pageInfo, messengers, translations, networks }} />
            </div>
          </div>
        </section>
      </HorizontalContainer>
    </PageWrapper>
  );
};

export default ContactsPageTemplate;

export const query = graphql`
  query ContactsPageQuery($locale: String!) {
    datoCmsContactsPage(locale: { eq: $locale }) {
      locale
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      title
      contactTitle
      contactFormTitle
      contactFormDescription
      contactFormInputLabel
      contactFormInputPlaceholder
      contactFormTextareaLabel
      contactFormTextareaPlaceholder
      contactFormButtonText
      pageInfo {
        value
      }
      messengersList
    }
    datoCmsHowToRequest(locale: { eq: $locale }) {
      title
      instruction {
        value
      }
      applyRequestButton
      applyRequestForm
    }
    datoCmsMiscTextString(locale: { eq: $locale }) {
      locale
      phone
      email
    }
    datoCmsFooter(locale: { eq: $locale }) {
      contacts {
        fieldName
        isSocial
        links {
          id: originalId
          fieldName
          fieldValue
        }
      }
    }
  }
`;
