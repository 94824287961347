import { Fragment } from 'react';
import { StructuredText } from 'react-datocms';

import { HeadingSmall } from 'components/UI/Headings';

import { Wrapper, Phone, Social } from './styles';

const Content = ({ pageInfo, messengers, translations, networks }) => {
  const { phone, email } = translations || {};

  return (
    <Wrapper>
      <StructuredText data={pageInfo?.value} />
      <HeadingSmall href={`mailto:${email}`} as="a">
        {email}
      </HeadingSmall>
      <Phone>
        <HeadingSmall href={`tel:${phone}`} as="a">
          {phone}
        </HeadingSmall>
        <span>{messengers}</span>
      </Phone>
      <Social>
        {networks.map((network, i) => {
          const { isSocial, fieldName, links } = network;

          return (
            isSocial && (
              <Fragment key={i}>
                <p>{fieldName}</p>
                <ul>
                  {links.map((link) => (
                    <li key={link.id}>
                      <a
                        href={link.fieldValue}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {link.fieldName}
                      </a>
                    </li>
                  ))}
                </ul>
              </Fragment>
            )
          );
        })}
      </Social>
    </Wrapper>
  );
};

export default Content;
