import styled from 'styled-components';

import { HeadingSmall } from 'components/UI/Headings';

const Wrapper = styled.div`
  max-width: 27.5rem;
  margin-top: 0.5rem;
  row-gap: 1.5rem;
  display: grid;

  ${HeadingSmall} {
    color: var(--primary-color);
    margin-bottom: 0;

    &:hover {
      color: var(--primary-button-hover);
    }

    &:active {
      color: var(--primary-button-active);
    }
  }
`;

const Phone = styled.div`
  display: grid;

  span {
    font-size: 0.75rem;
    color: var(--button-text-color);
  }
`;

const Social = styled.div`
  p {
    margin-bottom: 0.25rem;
  }

  ul {
    display: grid;
    column-gap: 1.5rem;
    grid-auto-flow: column;
    width: fit-content;

    li a {
      font-size: 1rem;
      font-weight: var(--weight-medium);
    }
  }
`;

export { Wrapper, Phone, Social };
